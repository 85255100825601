import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { login, currentAccount, logout } from 'services/jwt.auth'
import actions from './actions'
import { USER_ROLE_ADMIN, USER_ROLE_STAFF } from 'constant'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(login, email, password)
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })

  if (success) {
    window.location.href = "/"
    // yield history.push('/')
    // notification.success({
    //   message: 'Login Berhasil',
    //   description: 'Selamat Datang Di Dashboard Mamang Sayur!',
    // })
  } else {
    notification.error({
      message: 'Gagal Masuk',
      description: 'Alamat email atau kata sandi salah!',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { id, name, email, roles, token } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar: '',
        roles: roles === 1 ? USER_ROLE_ADMIN : USER_ROLE_STAFF,
        token,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      roles: '',
      email: '',
      avatar: '',
      token: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
