import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";

import Layout from "layouts";

const routes = [
  // Dashboards
  {
    path: "/dashboard",
    Component: lazy(() => import("pages/dashboard")),
    exact: true,
  },
  {
    path: "/admin",
    Component: lazy(() => import("pages/admin")),
    exact: true,
  },
  // Products
  {
    path: "/products",
    Component: lazy(() => import("pages/product/all")),
    exact: true,
  },
  {
    path: "/products/empty",
    Component: lazy(() => import("pages/product/empty")),
    exact: true,
  },
  {
    path: "/products/form",
    Component: lazy(() => import("pages/product/form")),
    exact: true,
  },
  {
    path: "/products/setting",
    Component: lazy(() => import("pages/product/settings")),
    exact: true,
  },
  {
    path: "/products/noactive",
    Component: lazy(() => import("pages/product/unpublish")),
    exact: true,
  },

  // User Page
  {
    path: "/users/new",
    Component: lazy(() => import("pages/users/new")),
    exact: true,
  },
  {
    path: "/users",
    Component: lazy(() => import("pages/users/all")),
    exact: true,
  },
  {
    path: "/users/detail",
    Component: lazy(() => import("pages/users/detail")),
    exact: true,
  },
  {
    path: "/users/message",
    Component: lazy(() => import("pages/users/messages")),
    exact: true,
  },
  {
    path: "/users/withdraw",
    Component: lazy(() => import("pages/users/withdraw")),
    exact: true,
  },
  {
    path: "/users/redeem",
    Component: lazy(() => import("pages/users/voucher_redeem")),
    exact: true,
  },

  //Mitra Pages
  {
    path: "/mitras",
    Component: lazy(() => import("pages/mitra/all")),
    exact: true,
  },
  {
    path: "/mitras/verify",
    Component: lazy(() => import("pages/mitra/verify")),
    exact: true,
  },
  {
    path: "/mitras/new",
    Component: lazy(() => import("pages/mitra/new")),
    exact: true,
  },
  {
    path: "/mitras/detail",
    Component: lazy(() => import("pages/mitra/detail")),
    exact: true,
  },
  {
    path: "/mitras/withdraw",
    Component: lazy(() => import("pages/mitra/withdraws")),
    exact: true,
  },
  {
    path: "/mitras/message",
    Component: lazy(() => import("pages/mitra/messages")),
    exact: true,
  },
  // Region Pages
  {
    path: "/region",
    Component: lazy(() => import("pages/regions")),
    exact: true,
  },
  // Ongkir Pages
  {
    path: "/ongkir",
    Component: lazy(() => import("pages/ongkir")),
    exact: true,
  },
  // Voucher Pages
  {
    path: "/voucher",
    Component: lazy(() => import("pages/vouchers")),
    exact: true,
  },
  {
    path: "/voucher/form",
    Component: lazy(() => import("pages/vouchers/form")),
    exact: true,
  },
  {
    path: "/banks",
    Component: lazy(() => import("pages/banks")),
    exact: true,
  },
  {
    path: "/cancel-reason",
    Component: lazy(() => import("pages/reason")),
    exact: true,
  },
  // Banner Pages
  {
    path: "/banners",
    Component: lazy(() => import("pages/banners")),
    exact: true,
  },
  // Broadcast Pages
  {
    path: "/broadcasts",
    Component: lazy(() => import("pages/broadcasts")),
    exact: true,
  },
  // Orders
  {
    path: "/orders/pending",
    Component: lazy(() => import("pages/orders/pending")),
    exact: true,
  },
  {
    path: "/orders/process",
    Component: lazy(() => import("pages/orders/process")),
    exact: true,
  },
  {
    path: "/orders/sending",
    Component: lazy(() => import("pages/orders/sending")),
    exact: true,
  },
  {
    path: "/orders/success",
    Component: lazy(() => import("pages/orders/success")),
    exact: true,
  },
  {
    path: "/orders/cancel",
    Component: lazy(() => import("pages/orders/cancel")),
    exact: true,
  },
  {
    path: "/orders",
    Component: lazy(() => import("pages/orders/all")),
    exact: true,
  },
  {
    path: "/orders/invoices",
    Component: lazy(() => import("pages/orders/invoices")),
    exact: true,
  },
  // Report Pages
  {
    path: "/reports",
    Component: lazy(() => import("pages/reports")),
    exact: true,
  },
  // Feed Pages
  {
    path: "/feeds",
    Component: lazy(() => import("pages/feeds")),
    exact: true,
  },
  // Account Stores
  {
    path: "/store",
    Component: lazy(() => import("pages/store")),
    exact: true,
  },
  // Deactive Payment
  {
    path: "/deactive_payment",
    Component: lazy(() => import("pages/deactive_payment")),
    exact: true,
  },
  // Payment Terms
  {
    path: "/payment_terms",
    Component: lazy(() => import("pages/payment_terms")),
    exact: true,
  },
  // Order group
  {
    path: "/order_group",
    Component: lazy(() => import("pages/order_group")),
    exact: true,
  },
  // CS Account
  {
    path: "/staff",
    Component: lazy(() => import("pages/staff")),
    exact: true,
  },
  // Role User
  {
    path: "/roles",
    Component: lazy(() => import("pages/roles")),
    exact: true,
  },
  // Payment Gateway
  {
    path: "/payment_guide",
    Component: lazy(() => import("pages/payment_gateway/guide")),
    exact: true,
  },
  //
  {
    path: "/payment_instant",
    Component: lazy(() => import("pages/payment_instant")),
    exact: true,
  },
  // Auth Pages
  {
    path: "/auth/login",
    Component: lazy(() => import("pages/auth/login")),
    exact: true,
  },
  {
    path: "/auth/404",
    Component: lazy(() => import("pages/auth/404")),
    exact: true,
  },
  {
    path: "/auth/500",
    Component: lazy(() => import("pages/auth/500")),
    exact: true,
  },
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
});

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
