const BASE_URL = "https://mamangsayur.com";
// const BASE_URL = "http://test.sahabatmamang.com";
// const BASE_URL = "http://localhost:8000";
const API_URL = `${BASE_URL}/api/`;
const URL_CATEGORY_PRODUCT = `${BASE_URL}/storage/assets/images/category/`;
const URL_COVER_PRODUCT = `${BASE_URL}/storage/assets/images/products/`;
const URL_PHOTO_PROFILE = `${BASE_URL}/storage/assets/images/users/`;
const URL_PHOTO_MITRA = `${BASE_URL}/storage/assets/images/mitras/`;
const URL_PHOTO_VOUCHER = `${BASE_URL}/storage/assets/images/voucher/`;
const URL_PHOTO_BANK = `${BASE_URL}/storage/assets/images/banks/`;
const URL_PHOTO_PAYMENT = `${BASE_URL}/storage/assets/images/payment/`;
const URL_PHOTO_BANNER = `${BASE_URL}/storage/assets/images/banners/`;

const EXPORT_REPORT = `${API_URL}admins/export/report/`;
const EXPORT_REPORT_EXCEL = EXPORT_REPORT + "excel/";
const EXPORT_REPORT_PDF = EXPORT_REPORT + "pdf";
const EXPORT_REPORT_ORDER_PENDING = EXPORT_REPORT_EXCEL + "order/pending";
const EXPORT_REPORT_ORDERS = EXPORT_REPORT_EXCEL + "orders";
const EXPORT_REPORT_REPORTS = EXPORT_REPORT_EXCEL + "reports";
const EXPORT_REPORT_USERS = EXPORT_REPORT_EXCEL + "users";
const EXPORT_REPORT_MITRA = EXPORT_REPORT_EXCEL + "mitra";
const EXPORT_REPORT_USER_DOWNLOAD = EXPORT_REPORT_EXCEL + "user/download";
const EXPORT_REPORT_MARGIN_SALES = EXPORT_REPORT_EXCEL + "margin/sales";

const PUSHER_APP_KEY = "47bb9e3723b6b91de261";
const PUSHER_CLUSTER = "ap1";

const FIREBASE_BROADCAST_CLIENT = "MAMANG_SAYUR_CLIENT";
const FIREBASE_BROADCAST_MITRA = "MAMANG_SAYUR_MITRA";
const FIREBASE_BROADCAST_GLOBAL = "MAMANG_SAYUR_GLOBAL";

const USER_ROLE_ADMIN = "ADMIN";
const USER_ROLE_STAFF = "STAFF";

export {
  BASE_URL,
  API_URL,
  URL_CATEGORY_PRODUCT,
  URL_COVER_PRODUCT,
  URL_PHOTO_PROFILE,
  URL_PHOTO_MITRA,
  URL_PHOTO_VOUCHER,
  URL_PHOTO_BANK,
  URL_PHOTO_BANNER,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  FIREBASE_BROADCAST_CLIENT,
  FIREBASE_BROADCAST_MITRA,
  FIREBASE_BROADCAST_GLOBAL,
  USER_ROLE_ADMIN,
  USER_ROLE_STAFF,
  EXPORT_REPORT_PDF,
  EXPORT_REPORT_ORDER_PENDING,
  EXPORT_REPORT_ORDERS,
  EXPORT_REPORT_REPORTS,
  EXPORT_REPORT_USERS,
  EXPORT_REPORT_MITRA,
  EXPORT_REPORT_USER_DOWNLOAD,
  EXPORT_REPORT_MARGIN_SALES,
  URL_PHOTO_PAYMENT,
};
