import React from 'react'
import style from './style.module.scss'
import { Divider } from 'antd'
import { history } from 'index'

export default function Index(props){
    const View = (data) => (
        <div className="text-gray-6">
            <ul className="list-unstyled">
                {data.map((item, i) => (
                    <div key={i} style={{cursor: 'pointer'}} onClick={() => history.push(item.link)}>
                        <li className="mb-3">
                            <div className={style.head}>
                                <p className={style.title}>
                                    <strong className="text-black"> {item.count}  </strong>
                                    {item.title}
                                </p>
                                <time className={style.time}>sekarang</time>
                            </div>
                            <p>{item.message}</p>
                        </li>
                        <Divider dashed />
                    </div>
                ))}
            </ul>
        </div>
    )
    
    const Empty = (e) => (
        <div className="text-center mb-3 py-4 bg-light rounded">{`${e} Kosong`}</div>
    )

    const checkZero = (value) => {
        return value === 0
    }

    const Content = () => {
        let {data, keys, title} = props
        
        if (data === null) {
            return Empty(title)
        }

        switch (keys) {
            case '1':
                if (checkZero(data.pending) && checkZero(data.process) && checkZero(data.sending)) {
                    return Empty(title)
                }
                return View([
                    { link: '/orders/pending', count: data.pending, title: 'Menunggu Konfirmasi', message: 'Pesanan Menunggu Dikonfirmasi'},
                    { link: '/orders/process', count: data.process, title: 'Sedang Diproses', message: 'Pesanan Sesang Diproses'},
                    { link: '/orders/sending', count: data.sending, title: 'Dikirim', message: 'Pesanan Sedang Dikirim'}
                ])
            case '2':
                if (checkZero(data)) {
                    return Empty(title)
                }
                return View([
                    { link: '/products/empty', count: data, title: 'Produk Kosong', message: 'Stok Produk Kosong'}
                ])
            case '3':
                if (checkZero(data.verification) && checkZero(data.withdraw)) {
                    return Empty(title)
                }
                return View([
                    { link: '/mitras/verify', count: data.verification, title: 'Menunggu Di Verifikasi', message: 'Mitra Sedang Menunggu Di Verifikasi'},
                    { link: '/mitras/withdraw', count: data.withdraw, title: 'Penarikan Saldo', message: 'Penarikan Saldo Mitra Menunggu Di Verifikasi'}
                ])

            case '4':
                if (checkZero(data.withdraw)) {
                    return Empty(title)
                }
                return View([
                    { link: '/users/withdraw', count: data.withdraw, title: 'Penarikan Saldo', message: 'Penarikan Saldo User Menunggu Di Verifikasi'}
                ])
            default:
                return Empty(title)
        }
    }

    return <Content />
}