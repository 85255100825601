import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <br />
        <p className="mb-0">Copyright © 2020 Mamang Sayur</p>
      </div>
    </div>
  )
}

export default Footer
