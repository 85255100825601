import React, { useState, useEffect } from "react";
import { Dropdown, Badge, notification } from "antd";
import Lists from "./Lists";
import styles from "./style.module.scss";
import { alert } from "services/api";
import Pusher from "pusher-js";
import { history } from "index";
import { connect } from "react-redux";
import { PUSHER_APP_KEY, PUSHER_CLUSTER } from "constant";

const pusher = new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_CLUSTER,
});

const Actions = ({ dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [count, setCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    fetchData();
    fetchEvent();
  }, []);

  const fetchData = async () => {
    setUpdateCount(0);
    setLoading(true);
    await alert()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
        setCount(res.data.data.count);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status === 401) {
          dispatch({
            type: "user/LOGOUT",
          });
          history.push("/auth/login");
        }
      });
  };

  const fetchEvent = () => {
    let channel = pusher.subscribe("new-message");
    channel.bind("App\\Events\\NewNotification", (e) => {
      notification.info({
        message: "Notifikasi Baru",
        description: e.message,
      });
      setUpdateCount(1);
    });
  };

  const menu = (
    <React.Fragment>
      <div className="card cui__utils__shadow width-350 border-0">
        <div className="card-body p-0">
          <Lists loading={loading} data={data} />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <div style={{ cursor: "pointer" }} onClick={() => fetchData()}>
        <Badge count={count + updateCount}>
          <i className={`${styles.icon} fe fe-bell`} />
        </Badge>
      </div>
    </Dropdown>
  );
};

export default connect()(Actions);
