import axios from "axios";
import store from "store";
import {
  API_URL,
  EXPORT_REPORT_MARGIN_SALES,
  EXPORT_REPORT_MITRA,
  EXPORT_REPORT_ORDERS,
  EXPORT_REPORT_ORDER_PENDING,
  EXPORT_REPORT_PDF,
  EXPORT_REPORT_REPORTS,
  EXPORT_REPORT_USERS,
  EXPORT_REPORT_USER_DOWNLOAD,
} from "../constant";

const base_url = API_URL;
const user =
  store.get("jwt") !== undefined ? JSON.parse(atob(store.get("jwt"))) : null;

const options = {
  headers: {
    Authorization: `Bearer ${user !== null ? user.token : ""}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const optionExport = {
  headers: {
    Authorization: `Bearer ${user !== null ? user.token : ""}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
  responseType: "arraybuffer",
};

//products
export async function products(page, q) {
  return await axios.get(
    `${base_url}admins/products?q=${q}&page=${page}`,
    options
  );
}

export async function product_noactive(id) {
  return await axios.get(`${base_url}admins/products/${id}/noactive`, options);
}

export async function product_active(id) {
  return await axios.get(`${base_url}admins/products/${id}/active`, options);
}

export async function product_empty(page, q) {
  return await axios.get(
    `${base_url}admins/products/empty?q=${q}&page=${page}`,
    options
  );
}

export async function product_detail(id) {
  return await axios.get(`${base_url}admins/products/${id}/detail`, options);
}

export async function product_insert(data) {
  return await axios.post(`${base_url}admins/products`, data, options);
}

export async function product_unpublish(page, q) {
  return await axios.get(
    `${base_url}admins/products/unpublish?q=${q}&page=${page}`,
    options
  );
}

export async function product_update(id, data) {
  return await axios.put(`${base_url}admins/products/${id}`, data, options);
}

// category
export async function category_show(page, q) {
  return await axios.get(
    `${base_url}admins/category?q=${q}&page=${page}`,
    options
  );
}

export async function category_noactive(id) {
  return await axios.get(`${base_url}admins/category/${id}/noactive`, options);
}

export async function category_active(id) {
  return await axios.get(`${base_url}admins/category/${id}/active`, options);
}

export async function category() {
  return await axios.get(`${base_url}admins/categories`, options);
}

export async function category_delete(id) {
  return await axios.delete(`${base_url}admins/category/${id}`, options);
}

export async function category_insert(data) {
  return await axios.post(`${base_url}admins/category`, data, options);
}

export async function category_update(id, data) {
  return await axios.put(`${base_url}admins/category/${id}`, data, options);
}

// type
export async function type_qty(q) {
  return await axios.get(`${base_url}admins/type-qty?q=${q}`, options);
}

export async function type_weight(q) {
  return await axios.get(`${base_url}admins/type-weight?q=${q}`, options);
}

//users
export async function new_user(data) {
  return await axios.post(`${base_url}admins/users`, data, options);
}

export async function users(page, q) {
  return await axios.get(
    `${base_url}admins/users?q=${q}&page=${page}`,
    options
  );
}

export async function user_active(id) {
  return await axios.get(`${base_url}admins/users/${id}/active`, options);
}

export async function user_deactive(id) {
  return await axios.get(`${base_url}admins/users/${id}/noactive`, options);
}

export async function user_detail(id) {
  return await axios.get(`${base_url}admins/users/${id}/detail`, options);
}

export async function user_update(id, data) {
  return await axios.put(`${base_url}admins/users/${id}`, data, options);
}

export async function user_message_list(page, q) {
  return await axios.get(
    `${base_url}admins/messages?q=${q}&page=${page}`,
    options
  );
}

export async function user_message_item(id) {
  return await axios.get(`${base_url}admins/messages/${id}/view`, options);
}

export async function user_message_reply(data) {
  return await axios.post(`${base_url}admins/messages/reply`, data, options);
}

export async function user_message_read(id) {
  return await axios.get(`${base_url}admins/messages/${id}/read`, options);
}

export async function user_message_clear(id) {
  return await axios.delete(`${base_url}admins/messages/${id}/clear`, options);
}

export async function user_message_compose(data) {
  return await axios.post(`${base_url}admins/messages/compose`, data, options);
}

export async function user_referral(phone, page) {
  return await axios.get(
    `${base_url}admins/users/referral/${phone}?page=${page}`,
    options
  );
}

export async function user_voucher_redeem(page, q) {
  return await axios.get(
    `${base_url}admins/users/voucher/redeem?q=${encodeURI(q)}&page=${page}`,
    options
  );
}

export async function user_voucher_redeem_accept(data) {
  return await axios.post(
    `${base_url}admins/users/voucher/redeem`,
    data,
    options
  );
}

// MITRA

export async function mitras(page, q) {
  return await axios.get(
    `${base_url}admins/mitra?q=${q}&page=${page}`,
    options
  );
}

export async function mitra_active(id) {
  return await axios.get(`${base_url}admins/mitra/${id}/active`, options);
}

export async function mitra_deactive(id) {
  return await axios.get(`${base_url}admins/mitra/${id}/noactive`, options);
}

export async function mitras_pending(page, q) {
  return await axios.get(
    `${base_url}admins/mitra/pending?q=${q}&page=${page}`,
    options
  );
}

export async function mitra_new(data) {
  return await axios.post(`${base_url}admins/mitra`, data, options);
}

export async function mitra_detail(id) {
  return await axios.get(`${base_url}admins/mitra/${id}/detail`, options);
}

export async function mitra_update(id, data) {
  return await axios.put(`${base_url}admins/mitra/${id}`, data, options);
}

export async function mitra_banks(id, page) {
  return await axios.get(
    `${base_url}admins/mitra/${id}/banks?page=${page}`,
    options
  );
}

export async function mitra_withdraws(id, page) {
  return await axios.get(
    `${base_url}admins/mitra/${id}/withdraws?page=${page}`,
    options
  );
}

export async function mitra_list_withdraws(page, q) {
  return await axios.get(
    `${base_url}admins/mitra/withdraws?q=${q}&page=${page}`,
    options
  );
}

export async function mitra_verify_withdraws(data) {
  return await axios.post(
    `${base_url}admins/mitra/withdraw/confirm`,
    data,
    options
  );
}

export async function mitra_cancel_withdraw(data) {
  return await axios.post(
    `${base_url}admins/mitra/withdraw/cancel`,
    data,
    options
  );
}

export async function mitra_message_list(page, q) {
  return await axios.get(
    `${base_url}admins/mitra/messages?q=${q}&page=${page}`,
    options
  );
}

export async function mitra_message_item(id) {
  return await axios.get(
    `${base_url}admins/mitra/messages/${id}/view`,
    options
  );
}

export async function mitra_message_reply(data) {
  return await axios.post(
    `${base_url}admins/mitra/messages/reply`,
    data,
    options
  );
}

export async function mitra_message_read(id) {
  return await axios.get(
    `${base_url}admins/mitra/messages/${id}/read`,
    options
  );
}

export async function mitra_message_clear(id) {
  return await axios.delete(
    `${base_url}admins/mitra/messages/${id}/clear`,
    options
  );
}

export async function mitra_message_compose(data) {
  return await axios.post(
    `${base_url}admins/mitra/messages/compose`,
    data,
    options
  );
}

export async function mitra_view(id, page, q) {
  return await axios.get(
    `${base_url}admins/mitra/${id}/view?q=${q}&page=${page}`,
    options
  );
}

export async function mitra_view_all(page, q) {
  return await axios.get(
    `${base_url}admins/mitra/views?q=${q}&page=${page}`,
    options
  );
}

// region
export async function regions_all() {
  return await axios.get(`${base_url}admins/regions/all`, options);
}

export async function regions(page, q) {
  return await axios.get(
    `${base_url}admins/regions?q=${q}&page=${page}`,
    options
  );
}

export async function regions_insert(data) {
  return await axios.post(`${base_url}admins/regions`, data, options);
}

export async function regions_update(id, data) {
  return await axios.put(`${base_url}admins/regions/${id}`, data, options);
}

export async function regions_active(id) {
  return await axios.get(`${base_url}admins/regions/${id}/active`, options);
}

export async function regions_deactive(id) {
  return await axios.get(`${base_url}admins/regions/${id}/deactive`, options);
}
// address
export async function find_city(q) {
  return await axios.get(`${base_url}admins/address/city?q=${q}`, options);
}
// ongkir
export async function ongkir(page, q) {
  return await axios.get(
    `${base_url}admins/ongkir?q=${q}&page=${page}`,
    options
  );
}

export async function ongkir_insert(data) {
  return await axios.post(`${base_url}admins/ongkir`, data, options);
}

export async function ongkir_update(id, data) {
  return await axios.put(`${base_url}admins/ongkir/${id}`, data, options);
}

export async function ongkir_active(id) {
  return await axios.get(`${base_url}admins/ongkir/${id}/active`, options);
}

export async function ongkir_deactive(id) {
  return await axios.get(`${base_url}admins/ongkir/${id}/noactive`, options);
}

export async function ongkir_type() {
  return await axios.get(`${base_url}admins/ongkir/type`, options);
}
export async function ongkir_by_roles(id) {
  return await axios.get(`${base_url}admins/ongkir/roles/${id}`, options);
}
export async function ongkir_by_shifts(id) {
  return await axios.get(`${base_url}admins/ongkir/shifts/${id}`, options);
}

// voucher
export async function vouchers(page, q) {
  return await axios.get(
    `${base_url}admins/vouchers?q=${q}&page=${page}`,
    options
  );
}

export async function vouchers_insert(data) {
  return await axios.post(`${base_url}admins/vouchers`, data, options);
}

export async function vouchers_update(id, data) {
  return await axios.put(`${base_url}admins/vouchers/${id}`, data, options);
}

export async function vouchers_active(id) {
  return await axios.get(`${base_url}admins/vouchers/${id}/active`, options);
}

export async function vouchers_deactive(id) {
  return await axios.get(`${base_url}admins/vouchers/${id}/noactive`, options);
}

export async function vouchers_detail(id) {
  return await axios.get(`${base_url}admins/vouchers/${id}/detail`, options);
}

export async function vouchers_type() {
  return await axios.get(`${base_url}admins/vouchers/type`, options);
}

export async function vouchers_discount() {
  return await axios.get(`${base_url}admins/vouchers/discount`, options);
}
// banks
export async function banks(page, q) {
  return await axios.get(
    `${base_url}admins/banks?q=${q}&page=${page}`,
    options
  );
}

export async function banks_insert(data) {
  return await axios.post(`${base_url}admins/banks`, data, options);
}

export async function banks_update(id, data) {
  return await axios.put(`${base_url}admins/banks/${id}`, data, options);
}

export async function banks_active(id) {
  return await axios.get(`${base_url}admins/banks/${id}/active`, options);
}

export async function banks_deactive(id) {
  return await axios.get(`${base_url}admins/banks/${id}/noactive`, options);
}

// banners
export async function banners(page, q) {
  return await axios.get(
    `${base_url}admins/banners?q=${q}&page=${page}`,
    options
  );
}

export async function banners_insert(data) {
  return await axios.post(`${base_url}admins/banners`, data, options);
}

export async function banners_update(id, data) {
  return await axios.put(`${base_url}admins/banners/${id}`, data, options);
}

export async function banners_delete(id) {
  return await axios.delete(`${base_url}admins/banners/${id}`, options);
}

export async function banners_active(id) {
  return await axios.get(`${base_url}admins/banners/${id}/active`, options);
}

export async function banners_deactive(id) {
  return await axios.get(`${base_url}admins/banners/${id}/noactive`, options);
}

// orders
export async function orders_pending(page, q) {
  return await axios.get(
    `${base_url}admins/orders/pending?q=${q}&page=${page}`,
    options
  );
}

export async function orders_detail(id) {
  return await axios.get(`${base_url}admins/orders/${id}/detail`, options);
}

export async function orders_confirmation(data) {
  return await axios.post(
    `${base_url}admins/orders/confirmation`,
    data,
    options
  );
}

export async function orders_process(page, q) {
  return await axios.get(
    `${base_url}admins/orders/process?q=${q}&page=${page}`,
    options
  );
}

export async function orders_sending(page, q) {
  return await axios.get(
    `${base_url}admins/orders/sending?q=${q}&page=${page}`,
    options
  );
}

export async function orders_success(page, q) {
  return await axios.get(
    `${base_url}admins/orders/success?q=${q}&page=${page}`,
    options
  );
}

export async function orders_cancel(page, q) {
  return await axios.get(
    `${base_url}admins/orders/cancel?q=${q}&page=${page}`,
    options
  );
}

export async function orders(page, q) {
  return await axios.get(
    `${base_url}admins/orders?q=${q}&page=${page}`,
    options
  );
}

export async function orders_cancelled(data) {
  return await axios.post(`${base_url}admins/orders/cancel`, data, options);
}

export async function order_change_invoice(data) {
  return await axios.post(
    `${base_url}admins/orders/change/invoice`,
    data,
    options
  );
}

// reports
export async function reports(status, month, year, page) {
  return await axios.get(
    `${base_url}admins/reports?status=${status}&month=${month}&year=${year}&page=${page}`,
    options
  );
}

// home
export async function home() {
  return await axios.get(`${base_url}admins/home`, options);
}

export async function home_download() {
  return await axios.get(`${base_url}admins/home/downloads`, options);
}

// alert
export async function alert() {
  return await axios.get(`${base_url}admins/alert`, options);
}

//Feed
export async function feed(page, q) {
  return await axios.get(`${base_url}admins/feed?q=${q}&page=${page}`, options);
}

export async function feed_insert(data) {
  return await axios.post(`${base_url}admins/feed`, data, options);
}

export async function feed_update(id, data) {
  return await axios.put(`${base_url}admins/feed/${id}`, data, options);
}

export async function feed_delete(id) {
  return await axios.delete(`${base_url}admins/feed/${id}`, options);
}

export async function feed_active(id) {
  return await axios.get(`${base_url}admins/feed/${id}/active`, options);
}

export async function feed_deactive(id) {
  return await axios.get(`${base_url}admins/feed/${id}/deactive`, options);
}

//Feed Product
export async function feeds(id, page, q) {
  return await axios.get(
    `${base_url}admins/feeds/${id}?q=${q}&page=${page}`,
    options
  );
}

export async function feeds_insert(data) {
  return await axios.post(`${base_url}admins/feeds`, data, options);
}

export async function feeds_delete(id) {
  return await axios.delete(`${base_url}admins/feeds/${id}`, options);
}

export async function feeds_products(page, q) {
  return await axios.get(
    `${base_url}admins/feeds/products/all?q=${q}&page=${page}`,
    options
  );
}

export async function cancel_reason() {
  return await axios.get(`${base_url}admins/reason/cancel`, options);
}

export async function user_saldo_increment(data) {
  return await axios.post(
    `${base_url}admins/users/saldo/increment`,
    data,
    options
  );
}

export async function user_saldo_decrement(data) {
  return await axios.post(
    `${base_url}admins/users/saldo/decrement`,
    data,
    options
  );
}

export async function user_point_increment(data) {
  return await axios.post(
    `${base_url}admins/users/point/increment`,
    data,
    options
  );
}

export async function user_point_decrement(data) {
  return await axios.post(
    `${base_url}admins/users/point/decrement`,
    data,
    options
  );
}

export async function user_withdraw(page, q) {
  return await axios.get(
    `${base_url}admins/users/withdraw?page=${page}&q=${q}`,
    options
  );
}

export async function user_withdraw_confirm(data) {
  return await axios.post(`${base_url}admins/users/withdraw`, data, options);
}

export async function user_withdraw_cancel(data) {
  return await axios.post(
    `${base_url}admins/users/withdraw/cancel`,
    data,
    options
  );
}

export async function mitra_saldo_increment(data) {
  return await axios.post(
    `${base_url}admins/mitra/saldo/increment`,
    data,
    options
  );
}

export async function mitra_saldo_decrement(data) {
  return await axios.post(
    `${base_url}admins/mitra/saldo/decrement`,
    data,
    options
  );
}

export async function cancel_reason_list(page, q) {
  return await axios.get(
    `${base_url}admins/reason/cancel/list?page=${page}&q=${q}`,
    options
  );
}

export async function cancel_reason_insert(data) {
  return await axios.post(`${base_url}admins/reason/cancel`, data, options);
}

export async function cancel_reason_update(id, data) {
  return await axios.put(
    `${base_url}admins/reason/cancel/update/${id}`,
    data,
    options
  );
}

export async function cancel_reason_delete(id) {
  return await axios.delete(
    `${base_url}admins/reason/cancel/delete/${id}`,
    options
  );
}

export async function firebase_send(data) {
  return await axios.post(`${base_url}admins/firebase/topic`, data, options);
}

export async function admin_update(data) {
  return await axios.post(`${base_url}admins/admin/update`, data, options);
}

export async function store_show() {
  return await axios.get(`${base_url}admins/store`, options);
}

export async function store_update(data) {
  return await axios.post(`${base_url}admins/store`, data, options);
}

export async function social() {
  return await axios.get(`${base_url}admins/social`, options);
}

export async function socail_insert(data) {
  return await axios.post(`${base_url}admins/social`, data, options);
}

export async function social_update(id, data) {
  return await axios.post(`${base_url}admins/social/${id}`, data, options);
}

export async function social_delete(id) {
  return await axios.delete(`${base_url}admins/social/${id}`, options);
}

export async function staff_list(page) {
  return await axios.get(`${base_url}admins/staff?page=${page}`, options);
}

export async function staff_insert(data) {
  return await axios.post(`${base_url}admins/staff`, data, options);
}

export async function staff_update(id, data) {
  return await axios.post(`${base_url}admins/staff/edit/${id}`, data, options);
}

export async function staff_password_update(id, data) {
  return await axios.post(
    `${base_url}admins/staff/password/${id}`,
    data,
    options
  );
}

export async function staff_delete(id) {
  return await axios.delete(`${base_url}admins/staff/${id}`, options);
}

export async function user_roles(page, q) {
  return await axios.get(
    `${base_url}admins/roles?q=${q}&page=${page}`,
    options
  );
}

export async function user_roles_insert(data) {
  return axios.post(`${base_url}admins/roles`, data, options);
}

export async function user_roles_update(id, data) {
  return axios.post(`${base_url}admins/roles/edit/${id}`, data, options);
}

export async function user_roles_active(id) {
  return axios.post(`${base_url}admins/roles/active/${id}`, null, options);
}

export async function user_roles_deactive(id) {
  return axios.post(`${base_url}admins/roles/deactive/${id}`, null, options);
}

export async function user_roles_all() {
  return axios.get(`${base_url}admins/roles/all`, options);
}

//
export async function payment_region(page, q) {
  return axios.get(`${base_url}admins/payments?q=${q}&page=${page}`, options);
}

export async function payment_region_instant(page, q) {
  return axios.get(
    `${base_url}admins/payments/instant?q=${q}&page=${page}`,
    options
  );
}

export async function payment_region_insert(data) {
  return axios.post(`${base_url}admins/payments`, data, options);
}

export async function payment_region_insert_instant(data) {
  return axios.post(`${base_url}admins/payments/instant`, data, options);
}

export async function payment_region_update(id, data) {
  return axios.post(`${base_url}admins/payments/edit/${id}`, data, options);
}

export async function payment_region_update_instant(id, data) {
  return axios.post(
    `${base_url}admins/payments/instant/edit/${id}`,
    data,
    options
  );
}

export async function payment_region_delete(id) {
  return axios.delete(`${base_url}admins/payments/${id}`, options);
}

//
export async function payment_method_all() {
  return axios.get(`${base_url}admins/payment_method/all`, options);
}

// order group

export async function order_group_type() {
  return axios.get(`${base_url}admins/order_group/type`, options);
}

export async function order_group_type_update(id, data) {
  return axios.post(`${base_url}admins/order_group/type/${id}`, data, options);
}

export async function order_group_type_visibilty(id, status) {
  return axios.get(
    `${base_url}admins/order_group/type/visibility/${id}/${status}`,
    options
  );
}

export async function order_group_benefit(page, q) {
  return axios.get(
    `${base_url}admins/order_group/benefit?q=${q}&page=${page}`,
    options
  );
}

export async function order_group_benefit_insert(data) {
  return axios.post(`${base_url}admins/order_group/benefit`, data, options);
}

export async function order_group_benefit_update(id, data) {
  return axios.post(
    `${base_url}admins/order_group/benefit/${id}/update`,
    data,
    options
  );
}

export async function order_group_benefit_visibility(id, status) {
  return axios.get(
    `${base_url}admins/order_group/benefit/visibility/${id}/${status}`,
    options
  );
}

export async function order_group_benefit_delete(id) {
  return axios.delete(`${base_url}admins/order_group/benefit/${id}`, options);
}

// Payment Terms

export async function payment_terms() {
  return axios.get(`${base_url}admins/payment/terms`, options);
}

export async function payment_terms_update(data) {
  return axios.post(`${base_url}admins/payment/terms`, data, options);
}

// EXPORT
export async function export_user_download(start, end) {
  return axios.get(
    EXPORT_REPORT_USER_DOWNLOAD + `?start=${start}&end=${end}`,
    optionExport
  );
}

export async function export_order_pending() {
  return axios.get(EXPORT_REPORT_ORDER_PENDING, optionExport);
}

export async function export_orders(status, date) {
  return axios.get(
    EXPORT_REPORT_ORDERS + `?status=${status}&date=${date}`,
    optionExport
  );
}

export async function export_user(month, year) {
  return axios.get(
    EXPORT_REPORT_USERS + `?month=${month}&year=${year}`,
    optionExport
  );
}

export async function export_mitra(status, month, year) {
  return axios.get(
    EXPORT_REPORT_MITRA + `?status=${status}&month=${month}&year=${year}`,
    optionExport
  );
}

export async function export_report_pdf(status, month, year) {
  return axios.get(
    EXPORT_REPORT_PDF + `?status=${status}&month=${month}&year=${year}`,
    optionExport
  );
}

export async function export_report_excel(status, month, year) {
  return axios.get(
    EXPORT_REPORT_REPORTS + `?status=${status}&month=${month}&year=${year}`,
    optionExport
  );
}

export async function export_margin_sales(status, month, year) {
  return axios.get(
    EXPORT_REPORT_MARGIN_SALES +
      `?status=${status}&month=${month}&year=${year}`,
    optionExport
  );
}

// payment gateway

export async function payment_gateway_guide(page, q) {
  return axios.get(
    `${base_url}admins/instant?q=${encodeURI(q)}&page=${page}`,
    options
  );
}

export async function payment_gateway_guide_old() {
  return axios.get(`${base_url}admins/instant/old`, options);
}

export async function payment_gateway_guide_insert(data) {
  return axios.post(`${base_url}admins/instant`, data, options);
}

export async function payment_gateway_guide_update(id, data) {
  return axios.post(`${base_url}admins/instant/${id}/update`, data, options);
}

export async function payment_gateway_guide_delete(id) {
  return axios.delete(`${base_url}admins/instant/${id}`, options);
}

export async function payment_gateway_instant() {
  return axios.get(`${base_url}admins/instant/payment`, options);
}

// Payment Instant Activation

export async function payment_instant_status(page, q) {
  return axios.get(
    `${base_url}admins/instant/status?q=${encodeURI(q)}&page=${page}`,
    options
  );
}

export async function payment_instant_status_activated(data) {
  return axios.post(`${base_url}admins/instant/status`, data, options);
}
