import store from 'store'
import axios from 'axios'
import { API_URL } from 'constant'

export async function login(email, password) {
  const foo = new FormData()
  foo.append('email', email)
  foo.append('password', password)

  return axios
    .post(`${API_URL}login-admin`, foo)
    .then(json => {
      if (json.data.success) {
        store.set('jwt', btoa(JSON.stringify(json.data.data)))
      }
      return true
    })
    .catch(e => {
      console.log(e)
      return false
    })
}

export async function currentAccount() {
  const jwt = store.get('jwt')
  if (jwt) {
    return JSON.parse(atob(jwt))
  }
  return false
}

export async function logout() {
  store.remove('jwt')
  return true
}
