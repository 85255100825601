import { USER_ROLE_ADMIN } from "constant";

export default async function getMenuData() {
  return [
    {
      category: true,
      title: "Dashboard",
    },
    {
      title: "Dashboard",
      key: "dashboard",
      icon: "fe fe-home",
      url: "/dashboard",
    },
    {
      category: true,
      title: "Produk",
    },
    {
      title: "Pesanan",
      key: "transactions",
      icon: "fe fe-shopping-cart",
      count: 6,
      children: [
        {
          title: "Menunggu Konfirmasi",
          key: "pendingPayment",
          url: "/orders/pending",
        },
        {
          title: "Diproses Mitra",
          key: "processing",
          url: "/orders/process",
        },
        {
          title: "Sedang Dikirim",
          key: "pendingShipping",
          url: "/orders/sending",
        },
        {
          title: "Selesai",
          key: "success",
          url: "/orders/success",
        },
        {
          title: "Dibatalkan",
          key: "canceled",
          url: "/orders/cancel",
        },
        {
          title: "Semua Pesanan",
          key: "allTransaction",
          url: "/orders",
        },
      ],
    },
    {
      title: "Produk",
      key: "products",
      icon: "fe fe-package",
      count: 4,
      children: [
        {
          title: "Semua Produk",
          key: "allProducts",
          url: "/products",
        },
        {
          title: "Produk Kosong",
          key: "emptyProducts",
          url: "/products/empty",
        },
        {
          title: "Produk Tidak Aktif",
          key: "unpublishProducts",
          url: "/products/noactive",
        },
        {
          title: "Pengaturan Produk",
          key: "settingProducts",
          url: "/products/setting",
        },
      ],
    },
    {
      title: "User & Mitra",
      category: true,
    },
    {
      category: true,
      title: "User",
      icon: "fe fe-users",
      key: "users",
      count: 5,
      children: [
        {
          title: "User Baru",
          key: "newUser",
          url: "/users/new",
        },
        {
          title: "Semua User",
          key: "allUser",
          url: "/users",
        },
        {
          title: "Pesan User",
          key: "chatUser",
          url: "/users/message",
        },
        {
          title: "Penarikan Saldo",
          key: "withdrawUser",
          url: "/users/withdraw",
        },
        {
          title: "Penukaran Kupon",
          key: "voucherRedeem",
          url: "/users/redeem",
        },
      ],
    },
    {
      title: "Mitra",
      icon: "fe fe-user-check",
      count: 4,
      key: "mitra",
      children: [
        {
          title: "Semua Mitra",
          key: "allMitra",
          url: "/mitras",
        },
        {
          title: "Verifikasi Mitra",
          key: "verifyMitra",
          url: "/mitras/verify",
        },
        {
          title: "Pesan Mitra",
          key: "chatMitra",
          url: "/mitras/message",
        },
        {
          title: "Penarikan Saldo",
          key: "withdrawMitra",
          url: "/mitras/withdraw",
        },
      ],
    },
    {
      title: "Toko",
      category: true,
    },
    {
      title: "Laporan Pesanan",
      key: "report",
      icon: "fe fe-file-text",
      url: "/reports",
    },
    {
      title: "Pengaturan Toko",
      icon: "fe fe-settings",
      count: 7,
      key: "store-settings",
      children: [
        {
          title: "Role User",
          key: "roles",
          url: "/roles",
        },
        {
          title: "Region",
          key: "region",
          url: "/region",
        },
        {
          title: "Ongkir",
          key: "ongkir",
          url: "/ongkir",
        },
        {
          title: "Kupon",
          key: "voucher",
          url: "/voucher",
        },
        {
          title: "Belanja Bareng",
          key: "order_group",
          url: "/order_group",
        },
        {
          title: "Alasan Batal",
          key: "cancel_reason",
          url: "/cancel-reason",
        },
        {
          title: "Akun Toko",
          key: "store",
          url: "/store",
        },
      ],
    },
    {
      title: "Pembayaran",
      key: "payment",
      icon: "fe fe-credit-card",
      count: 5,
      children: [
        {
          title: "Panduan Pembayaran",
          key: "payment_guide",
          url: "/payment_guide",
        },
        {
          title: "Akun Bank",
          key: "banks",
          url: "/banks",
        },
        {
          title: "Nonaktif Pembayaran",
          key: "deactive_payment",
          url: "/deactive_payment",
        },
        {
          title: "Aktifasi Pembayaran Instan",
          key: "payment_instant",
          url: "/payment_instant",
        },
        {
          title: "Ketentuan Pengiriman",
          key: "payment_terms",
          url: "/payment_terms",
        },
      ],
    },
    {
      category: true,
      title: "Mobile App",
    },
    {
      title: "Android",
      icon: "fa fa-android",
      count: 3,
      key: "android",
      children: [
        {
          title: "Feed",
          key: "feeds",
          url: "/feeds",
        },
        {
          title: "Banner",
          key: "banner",
          url: "/banners",
        },
        {
          title: "Broadcast",
          key: "broadcast",
          url: "/broadcasts",
        },
      ],
    },
    {
      category: true,
      title: "Owner",
      roles: [USER_ROLE_ADMIN],
    },
    {
      title: "Staff Akun",
      icon: "fa fa-user",
      key: "staff",
      url: "/staff",
      roles: [USER_ROLE_ADMIN],
    },
    {
      category: true,
    },
  ];
}
