import React, { useState } from 'react'
import { Tabs, Spin } from 'antd'
import style from './style.module.scss'
import Item from './Item'

const { TabPane } = Tabs

export default function Index(props){

    const [key, setKey] = useState('1')

    return (
        <Spin spinning={props.loading}>
            <Tabs className={style.tabs} defaultActiveKey={key} key={key} onChange={(e) => setKey(e)}>
                <TabPane tab="Pesanan" key="1">
                    <Item
                        data={props.data.orders !== undefined ? props.data.orders : null}
                        title="Pesanan"
                        keys="1" />
                </TabPane>
                <TabPane tab="Produk" key="2">
                    <Item
                        data={props.data.product !== undefined ? props.data.product : null}
                        title="Produk"
                        keys="2" />    
                </TabPane>
                <TabPane tab="Mitra" key="3">
                    <Item
                        data={props.data.mitra !== undefined ? props.data.mitra : null}
                        title="Mitra"
                        keys="3" />
                </TabPane>
                <TabPane tab="User" key="4">
                    <Item
                        data={props.data.user !== undefined ? props.data.user : null}
                        title="User"
                        keys="4" />
                </TabPane>
            </Tabs>
        </Spin>
    )
}